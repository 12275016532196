<template>
  <tbody role="rowgroup">
  <template v-for="(row, rowIndex) in items">
    <slot name="previousTr" />
    <slot name="trContent" :row="row" :rowIndex="rowIndex" :update="updateCell">
      <tr :class="row.class" :key="rowIndex" @click="$emit('selectedRow', row)">
        <template v-for="(field, tdIndex) in flattenedFields">
          <slot :name="`td(${field.key})`" :row="row" :field="field" :value="row[field.key]">
            <td
                :key="tdIndex"
                :class="cellClasses(field, tdIndex, row)"
                :style="field.contentStyle"
                @click="handleCellClick(field, row)"
            >
              <slot
                  :name="`cell(${field.key})`"
                  :row="row"
                  :field="field"
                  :rowIndex="rowIndex"
                  :update="updateCell"
                  :value="row[field.key]"
              >
                <template v-if="itemsFormat === 'secondsToHMSReader'">
                  {{ secondsToHMSReader(row[field.key]) }}
                </template>
                <template v-else>
                  {{ row[field.key] }}
                </template>
              </slot>
            </td>
          </slot>
        </template>
      </tr>
    </slot>
    <slot name="nextTr" />
  </template>
  </tbody>
</template>

<script>
export default {
  name: 'BaseTableBody',
  props: {
    items: {
      type: Array,
      required: true
    },
    flattenedFields: {
      type: Array,
      required: true
    },
    itemsFormat: {
      type: String,
      default: ''
    },
    secondsToHMSReader: {
      type: Function,
      required: false
    }
  },
  methods: {
    handleCellClick (field, row) {
      this.$emit('cell-click', { field, row })
    },
    cellClasses (field, tdIndex, row) {
      return [
        this.rightDividerClass(field),
        this.leftDividerClass(field),
        this.stickyClass(tdIndex, field),
        field.customFunctionClass ? field.customFunctionClass(row[field.key], row) : '',
        field.class || ''
      ]
    },
    stickyClass (index, field) {
      const className = `position-sticky position-left-${index}`
      return { [className]: field.position === 'sticky' }
    },
    rightDividerClass (field) {
      return { 'right-divider': field.hasRightDivider }
    },
    leftDividerClass (field) {
      return { 'left-divider': field.hasLeftDivider }
    },
    updateCell (cellData) {
      this.$emit('update-cell', cellData)
    }
  }
}
</script>
