<template>
  <div>
    <div class="offer-provider-item d-flex align-items-center">
      <div :class="'offer-provider-branch offer-provider-' + id">{{ shortName }}</div>
      <div v-if="name" class="offer-provider-name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  computed: {
    shortName () {
      switch (parseInt(this.id)) {
      case 1:
        return 'AY'
      case 2:
        return 'BL'
      case 3:
        return 'BE'
      case 4:
        return 'AS'
      }
      return 'UNKNOWN'
    }
  }
}
</script>
