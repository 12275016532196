<template>
  <component :is="tagName">
      <slot :save="save" :clear="clearForm" :cancel="cancelForm"></slot>
  </component>
</template>

<script>
export default {
  props: {
    settings: {
      required: true,
      type: Object
    },
    tagName: {
      required: false,
      type: String,
      default: 'div'
    }
  },
  data () {
    return {
      postUrl: ''
    }
  },
  methods: {
    cancelForm () {
      this.clearForm()
      this.$emit('cancel')
    },
    clearForm () {
      Object.keys(this.settings.formElements).forEach((key) => {
        this.settings.formElements[key].value = this.settings.formElements[key].defaultValue
        this.settings.formElements[key].isValidated = false
        this.settings.formElements[key].isError = false
      })
    },
    processEdit (primaryValue) {
      this.clearForm()
      const getUrl = this.settings.getUrl.replace('{id}', primaryValue)
      this.postUrl = this.settings.editUrl.replace('{id}', primaryValue)
      this.showLoading()
      this.$http
        .get(getUrl)
        .then((response) => {
          const returnData = response.data
          this.putValueToForm(returnData)
          if (
            typeof this.settings.formElements[this.settings.primaryKey] === 'object' &&
                    this.settings.formElements[this.settings.primaryKey] !== null
          ) {
            this.settings.formElements[this.settings.primaryKey].value = primaryValue
            this.settings.formElements[this.settings.primaryKey].rules = ['required']
          }
          this.hideLoading()
        })
        .catch((errors) => {
          if (typeof errors.response === 'object') {
            this.alertError(errors.response.data)
          } else {
            this.alertError(errors.message)
          }
          this.hideLoading()
          this.$emit('failToGetData')
        })
    },
    processAddNew () {
      this.clearForm()
      this.postUrl = this.settings.addUrl
      if (
        typeof this.settings.formElements[this.settings.primaryKey] === 'object' &&
                this.settings.formElements[this.settings.primaryKey] !== null
      ) {
        this.settings.formElements[this.settings.primaryKey].rules = []
      }
    },
    putValueToForm (dataObj) {
      Object.keys(dataObj).forEach((key) => {
        if (
          typeof this.settings.formElements[key] === 'object' &&
                    typeof this.settings.formElements[key] !== null
        ) {
          if (typeof this.settings.formElements[key].setValue === 'function') {
            this.settings.formElements[key].setValue(this.settings.formElements[key], this.settings, dataObj)
          } else {
            this.settings.formElements[key].value = dataObj[key]
          }
        }
      })
    },
    save () {
      if (this.validate()) {
        this.showLoading()
        this.$http
          .post(this.postUrl, this.gatherPostData())
          .then((response) => {
            this.hideLoading()
            const returnData = response.data
            //Code to handle data after save successfully
            this.$emit('success', {
              form: this.gatherPostData(),
              response: returnData
            })
          })
          .catch((errors) => {
            if (typeof errors.response === 'object') {
              if (typeof errors.response.data.errors === 'object') {
                this.alertError(errors.response.data.errors)
              } else {
                this.alertError(errors.response.data)
              }
            } else {
              this.alertError(errors.message)
            }
            this.hideLoading()
          })

      }
    },
    gatherPostData () {
      const dataObj = {}
      Object.keys(this.settings.formElements).forEach((key) => {
        if (typeof this.settings.formElements[key].getValue === 'function') {
          dataObj[this.settings.formElements[key].post_param] = this.settings.formElements[key].getValue(this.settings.formElements[key], this.settings)
        } else {
          dataObj[this.settings.formElements[key].post_param] = this.settings.formElements[key].value
        }
      })
      return dataObj
    },
    executeValidate () {
      for (const refKey in this.$refs) {
        if (typeof this.$refs[refKey].executeElementValidate === 'function') {
          this.$refs[refKey].executeElementValidate()
        }
      }
      if (this.settings.vueContainer) {
        for (const refKey in this.settings.vueContainer.$refs) {
          if (this.settings.vueContainer.$refs[refKey] && typeof this.settings.vueContainer.$refs[refKey].executeElementValidate === 'function') {
            this.settings.vueContainer.$refs[refKey].executeElementValidate()
          }
        }
      }

    },
    validate () {
      let hasErrors = false
      this.executeValidate()
      Object.keys(this.settings.formElements).forEach((key) => {
        this.settings.formElements[key].isValidated = true
        hasErrors = hasErrors || this.settings.formElements[key].isError
      })
      return !hasErrors
    }
  },
  mounted () {
    if (typeof this.settings.addUrl === 'string') {
      this.postUrl = this.settings.addUrl
    }
    this.clearForm()
  }
}
</script>

<style>

</style>
