<template>
  <b-modal
      :id="modalUUid"
      :ref="modalUUid"
      size="lg"
      hide-footer
      title="Database View"
  >
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Column</th>
        <th>Value</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(value, key) in data" :key="key">
        <td>{{ key }}</td>
        <td class="word-pre-wrap">{{ value }}</td>
      </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: {
      required: true
    },
    id: {
      required: true
    }
  },
  data () {
    return {
      modalUUid: `database-view-modal${this.id}-${Math.random().toString(36).substr(2, 9)}`
    }
  },
  methods: {
    showModal () {
      this.$bvModal.show(this.modalUUid)
    }
  }
}
</script>
<style scoped>
  .word-pre-wrap {
    word-break: break-word;
    white-space: pre-wrap;
  }
</style>