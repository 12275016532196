<template>
    <b-modal
        :id="modalUUid"
        :ref="modalUUid"
        title="Activate User"
        :hide-footer="true"
    >
        <BaseForm
            ref="main_form"
            :settings="activateFormSettings"
            @success="successAddedUserBan"
            @cancel="$bvModal.hide(modalUUid)"
            v-slot="{ save, cancel }"
        >
            <b-alert :show="isNeedApproval" variant="warning" class="p-1">
              <b-icon icon="exclamation-circle" variant="warning" class="mr-50"></b-icon>
              <b class="text-warning">You need an approval for this action</b>
            </b-alert>

            <b-row class="mb-1" style="display: flex; align-items: center">
                <b-col cols="12 mb-1"> Protect from automatic Status changes for: </b-col>
              <template v-if="showBothFields">
                <b-col cols="12">
                  <div style="display: flex; gap: 20px;">
                    <div style="width: 60px; padding-top: 2px;">For</div>
                    <FormElement
                      ref="protected_completes"
                      style="margin-bottom: 0px;"
                      :elementSettings="activateFormSettings.formElements.protected_completes"
                      :formSettings="activateFormSettings"
                    />
                  </div>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <div style="display: flex;gap: 20px;">
                    <div style="width: 60px; padding-top: 2px;">For</div>
                    <FormElement
                      ref="protected_offers_payout"
                      style="margin-bottom: 0px;"
                      :elementSettings="activateFormSettings.formElements.protected_offers_payout"
                      :formSettings="activateFormSettings"
                      @focusout.native="handleFocusout"
                    />
                  </div>
                </b-col>
              </template>

              <!-- Show Only One Field Based on Comment -->
              <b-col cols="12" v-else>
                <div style="display: flex;gap: 20px;">
                  <div style="width: 60px; padding-top: 2px;">For</div>
                  <div v-if="showOffersPayout">
                    <FormElement
                      ref="protected_offers_payout"
                      style="margin-bottom: 0px;"
                      :elementSettings="activateFormSettings.formElements.protected_offers_payout"
                      :formSettings="activateFormSettings"
                      @focusout.native="handleFocusout"
                    />
                  </div>

                  <div v-else>
                    <FormElement
                      ref="protected_completes"
                      style="margin-bottom: 0px;"
                      :elementSettings="activateFormSettings.formElements.protected_completes"
                      :formSettings="activateFormSettings"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="2"> Internal Comment </b-col>
                <b-col cols="10">
                    <FormElement
                        ref="comment"
                        :elementSettings="activateFormSettings.formElements.comment"
                        :formSettings="activateFormSettings"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" @click="save" class="mr-1">
                        Save
                    </b-button>
                    <b-button variant="warning" @click="cancel()"
                        >Cancel</b-button
                    >
                </b-col>
            </b-row>
        </BaseForm>
    </b-modal>
</template>

<script>
export default {
  props:{
    userId: {
      required: true
    },
    isNeedApproval: {
      type: Boolean,
      default: false
    },
    status: {
      required: true
    },
    comment: {
      type: String,
      default: null,
      required: false
    }
  },
  data () {
    const isTestMode = this.isTestMode()
    const vueInstance = this
    return {
      modalUUid: `protected-user-modal${ this.userId }`,
      refreshKey: 'protected-user-modal',
      showBothFieldsErrorMessage: 'At least one of the fields is required!',
      protectedCompletesErrorMessage: 'Completes field is required!',
      protectedOffersPayoutErrorMessage: 'Publisher Payout Sum field is required!',
      activateFormSettings: {
        addUrl: `/api/user/${  this.userId  }/activate`,
        primaryKey: 'id',
        formElements: {
          protected_completes: {
            type: 'input',
            sub_type: 'number',
            defaultValue: null,
            value: null,
            errorClass: 'input-error',
            isValidated: false,
            isError: false,
            refreshKey: 'protected_completes',
            controlStyle: 'width: 200px;',
            post_param: 'protected_completes',
            place_holder: 'Number of completes',
            next_text: 'completes',
            validate (elementSettings, formSettings) {
              if (vueInstance.showBothFields)  return true
              if (vueInstance.showOffersPayout) return true

              return isTestMode || parseInt(formSettings.formElements.protected_completes?.value) > 0
            },
            get errorMessage () {
              return vueInstance.getCompletesErrorMessage()
            }
          },
          protected_offers_payout: {
            type: 'input',
            sub_type: 'number',
            defaultValue: null,
            value: null,
            errorClass: 'input-error',
            isValidated: false,
            isError: false,
            refreshKey: 'protected_offers_payout',
            controlStyle: 'width: 200px;',
            post_param: 'protected_offers_payout',
            place_holder: 'Publisher Payout Sum',
            next_text: '$ offer payout',
            validate (elementSettings, formSettings) {
              if (vueInstance.showBothFields) {
                return vueInstance.atLeastOneFieldFilled()
              }

              if (!vueInstance.showOffersPayout) return true

              return isTestMode || parseFloat(formSettings.formElements.protected_offers_payout?.value) > 0
            },
            get errorMessage () {
              return vueInstance.getOffersPayoutErrorMessage()
            }
          },
          comment: {
            type: 'textarea',
            defaultValue: '',
            post_param: 'comment',
            value: '',
            errorClass: 'input-error',
            errorMessage: 'Internal comment is required!',
            isValidated: false,
            place_holder: 'Input internal comment',
            isError: false
          }
        },
        vueContainer: this
      }
    }
  },
  computed: {
    showOffersPayout () {
      return this.comment && /offer|reward/i.test(this.comment)
    },
    showBothFields () {
      return [2, 3, 6].includes(this.status)
    }
  },
  watch: {
    watch: {
      comment: {
        immediate: true,
        handler (newValue) {
          if (this.showBothFields) return
          if (this.showOffersPayout) {
            this.activateFormSettings.formElements.protected_completes.value = null
          } else {
            this.activateFormSettings.formElements.protected_offers_payout.value = null
          }
        }
      }
    }
  },
  methods: {
    showForm () {
      this.$bvModal.show(this.modalUUid)

      this.$nextTick(() => {
        this.getFormVue().clearForm()
      })
    },
    atLeastOneFieldFilled () {
      return (
        parseFloat(this.activateFormSettings.formElements.protected_offers_payout?.value || 0) > 0 ||
        parseInt(this.activateFormSettings.formElements.protected_completes?.value || 0) > 0
      )
    },
    getCompletesErrorMessage () {
      return this.showBothFields ? '' : 'Completes field is required!'
    },
    getOffersPayoutErrorMessage () {
      return this.showBothFields ? 'At least one of the fields is required!' : 'Publisher Payout Sum field is required!'
    },
    successAddedUserBan (data) {
      this.$emit('activated', data.response)
      this.$bvModal.hide(this.modalUUid)
    },
    getFormVue () {
      return this.$refs.main_form
    },
    handleFocusout () {
      const val = this.activateFormSettings.formElements.protected_offers_payout.value
      if (val !== null && val !== '' && !isNaN(val)) {
        this.activateFormSettings.formElements.protected_offers_payout.value = parseFloat(val).toFixed(2)
      }
    }
  }
}
</script>

<style>
</style>
