<template>
  <ps-filters
      v-if="hasFilterSettings"
      :filterSettings.sync="filterSettings"
      :injectActions="injectActions"
      :firstSearch="firstSearch"
      :useQueryUrl="useQueryUrl"
      :isRowFilter="isRowFilter"
      :showImport="showImport"
      :showExport="showExport"
      :searchButtonText="searchButtonText"
      :dynamicFieldButtonAdd="dynamicFieldButtonAdd"
      :hideSearch="hideSearch"
      v-model="localFilterValues"
      @search="search"
      @exportToCSV="exportToCSV"
      @changed="$emit('changed')"
      @addDynamicField="$emit('addDynamicField')"
      @importFile="importFile"
      ref="filter"
      style="margin-bottom: 0; float: right"
  >
    <!-- Pass-through scoped slots -->
    <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="props">
      <slot :name="slotName" v-bind="props" />
    </template>
  </ps-filters>
</template>

<script>
import PsFilters from '@ps_main/custom-components/PsFilters.vue'

export default {
  name: 'BaseTableFilterBar',
  components: { PsFilters },
  props: {
    filterSettings: {
      type: [Array, Object],
      default: () => ({})
    },
    injectActions: {
      type: Object,
      default: () => ({})
    },
    firstSearch: {
      type: Boolean,
      default: true
    },
    useQueryUrl: {
      type: Boolean,
      default: true
    },
    isRowFilter: {
      type: Boolean,
      default: false
    },
    showImport: {
      type: Boolean,
      default: false
    },
    showExport: {
      type: Boolean,
      default: false
    },
    searchButtonText: {
      type: String,
      default: 'Search'
    },
    dynamicFieldButtonAdd: {
      type: String,
      default: 'Add Another'
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    // v-model binding
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasFilterSettings () {
      return Object.keys(this.filterSettings).length > 0
    }
  },
  data () {
    return {
      localFilterValues: this.value
    }
  },
  watch: {
    value (newVal) {
      this.localFilterValues = newVal
    },
    localFilterValues: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  methods: {
    search () {
      this.$emit('input', this.localFilterValues)
      this.$emit('search')
    },
    exportToCSV () {
      this.$emit('input', this.localFilterValues)
      this.$emit('exportToCSV')
    },
    importFile () {
      this.$emit('input', this.localFilterValues)
      this.$emit('importFile')
    }
  }
}
</script>
