<template>
  <div class="iap-label" :class="has_iap_data ? 'iap-enabled' : 'iap-disabled'">IAP</div>
</template>

<script>
export default {
  props: {
    has_iap_data: {
      type: Number,
      default: 0
    }
  }
}
</script>


<style scoped>
.iap-label {
  width: 65px;
  padding: 2px 8px;
  border-radius: 4px;
  text-align: center;
  color: #F4F6FD;
  font-weight: bold;
}
.iap-disabled {
  background: #929292;
}
.iap-enabled {
  background: #019051;
}
</style>
