<template>
  <div class="supplier-buyer-item">
    <div class="buyer-id d-block">
      <router-link
          v-if="canAccess('supplier_buyers')"
          :to="{
              name: 'supplier_buyers',
              params: { filterSupplierBuyer: supplierBuyer.id },
          }"
      >
        {{ supplierBuyer.id }}
      </router-link>
      <span v-else>{{ supplierBuyer.id }}</span>
    </div>
    <div class="text-nowrap d-flex">
      <SupplierElement v-if="supplierBuyer.supplier_id" class="justify-content-start" :id="supplierBuyer.supplier_id" />
      <div v-if="supplierBuyer.supplier_buyer_name" class="supplier-buyer-name">{{ supplierBuyer.supplier_buyer_name }}</div>
      <div v-if="supplierBuyer.supplier_buyer_id">({{ supplierBuyer.supplier_buyer_id }})</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supplierBuyer: {
      type: Object,
      required: true
    }
  }
}
</script>
