<template>
  <div>
    <router-link
        v-if="!withoutLinked && canAccess('supplier_details')"
        :to="{
              name: 'supplier_details',
              params: { supplierId: id },
            }"
    >
      <div class="supplier-item d-flex align-items-center">
        <div
            :class="[
              'supplier-branch',
              'supplier-' + mappedId,
              textColorClass
            ]"
        >{{ shortName }}</div>
        <div v-if="name" class="supplier-name">{{ name }}</div>
      </div>
    </router-link>
    <div class="supplier-item d-flex align-items-center" v-else>
      <div
          :class="[
            'supplier-branch',
            'supplier-' + mappedId,
            textColorClass
          ]"
      >{{ shortName }}</div>
      <div v-if="name" class="supplier-name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    withoutLinked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mappedId () {
      const map = {
        102: 2,
        103: 3,
        104: 4,
        105: 5,
        106: 6,
        107: 7,
        110: 10,
        111: 11,
        113: 13,
        117: 17,
        24: 8
      }
      return map[this.id] || this.id
    },
    shortName () {
      switch (this.mappedId) {
      case 1:
        return 'PI'
      case 2:
        return 'PS'
      case 3:
        return 'IN'
      case 4:
        return 'SG'
      case 5:
        return 'LC'
      case 6:
        return 'DY'
      case 7:
        return 'TL'
      case 8:
        return 'CI'
      case 9:
        return 'RFG'
      case 10:
        return 'PR'
      case 11:
        return 'MC'
      case 12:
        return 'PDI'
      case 13:
        return 'REP'
      case 14:
        return 'IP'
      case 15:
        return 'FC'
      case 16:
        return 'KT'
      case 17:
        return 'PRS'
      case 18:
        return 'WL'
      case 19:
        return 'OL'
      case 20:
        return 'DA'
      case 21:
        return 'TO'
      case 22:
        return 'EL'
      case 23:
        return 'FO'
      case 25:
        return 'OR'
      case 50:
        return 'PSA'
      case 100:
        return 'TES'
      default:
        return 'UNKNOWN'
      }
    },
    textColorClass () {
      return this.id > 100 || this.id === 24 ? 'text-blue' : 'text-white'
    }
  }
}
</script>

<style scoped>
.text-blue {
  color: blue !important;
}
</style>
