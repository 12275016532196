<template>
  <thead role="rowgroup">
  <!-- Single header row -->
  <tr role="row" v-if="!isMultipleHeader">
    <template v-for="(field, index) in fields">
      <th
          v-if="field.sortable !== false"
          @click="onSort(field.key)"
          role="columnheader"
          :key="index"
          :aria-sort="ariaSort(field.key)"
          :style="field.style"
          :class="[ rightDividerClass(field), leftDividerClass(field), stickyClass(index, field), field.class ]"
      >
        {{ field.label }}
      </th>
      <th v-else :style="field.style" :key="index">
        {{ field.label }}
      </th>
    </template>
  </tr>

  <!-- Multiple header rows -->
  <template v-else>
    <template v-for="(headerRow, rowIndex) in fields">
      <tr :key="rowIndex" role="row" class="border">
        <template v-for="(childField, colIndex) in headerRow">
          <th
              v-if="childField.sortable !== false && !childField.colspan"
              @click="onSort(childField.key)"
              role="columnheader"
              :key="colIndex"
              :aria-sort="ariaSort(childField.key)"
              :style="verticalStyle(childField.style)"
              :rowspan="childField.rowspan || 1"
              :colspan="childField.colspan || 1"
              :class="[ rightDividerClass(childField), leftDividerClass(childField), stickyClass(colIndex, childField), childField.class ]"
          >
            {{ childField.label }}
          </th>
          <th
              v-else
              :key="colIndex"
              :style="verticalStyle(childField.style)"
              :rowspan="childField.rowspan || 1"
              :colspan="childField.colspan || 1"
              :class="[ rightDividerClass(childField), leftDividerClass(childField), stickyClass(colIndex, childField) ]"
          >
            <slot :name="`header(${colIndex})`" :field="childField">
              {{ childField.label }}
            </slot>
          </th>
        </template>
      </tr>
    </template>
  </template>
  </thead>
</template>

<script>
export default {
  name: 'BaseTableHeader',
  props: {
    fields: {
      type: Array,
      required: true
    },
    orderBy: { type: String, default: '' },
    orderDirection: { type: String, default: 'desc' }
  },
  computed: {
    isMultipleHeader () {
      return Array.isArray(this.fields[0])
    }
  },
  methods: {
    onSort (key) {
      this.$emit('sort', key)
    },
    ariaSort (fieldKey) {
      if (this.orderBy === fieldKey) {
        return this.orderDirection === 'asc' ? 'ascending' : 'descending'
      }
      return 'none'
    },
    verticalStyle (styleValue) {
      return `vertical-align: middle;${  styleValue || ''}`
    },
    stickyClass (index, field) {
      const className = `position-sticky position-left-${index}`
      return { [className]: field.position === 'sticky' }
    },
    rightDividerClass (field) {
      return { 'right-divider': field.hasRightDivider }
    },
    leftDividerClass (field) {
      return { 'left-divider': field.hasLeftDivider }
    }
  }
}
</script>
