<template>
  <b-form-group v-bind="commonFormGroupProps">
    <b-form-tags
        v-model="internalTags"
        :separator="separatorList"
        :remove-on-delete="!!removeOnBackspaceComputed"
        :placeholder="placeholderComputed"
        :add-on-change="!!addOnChangeComputed"
        :tag-validator="item.validation !== undefined ? this.item.validation : null"
        @input="onInput"
        :id="idComputed"
        no-add-on-enter
    />
  </b-form-group>
</template>

<script>
import {formMixin} from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  name: 'FormTags',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    filterKey: {
      type: String,
      default: ''
    },
    value: {
      type: [Array, String],
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Add tags...'
    },
    enableSeparators: {
      type: Boolean,
      default: true
    },
    customSeparators: {
      type: Array,
      default: () => []
    },
    removeOnBackspace: {
      type: Boolean,
      default: true
    },
    addOnChange: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      internalTags: Array.isArray(this.value) ? [...this.value] : []
    }
  },
  computed: {
    idComputed () {
      if (this.id) return this.id
      return this.filterKey ? `${this.filterKey}-${this.item.refresh_uuid || 'default'}` : ''
    },
    placeholderComputed () {
      return this.item.placeholder || this.placeholder
    },
    removeOnBackspaceComputed () {
      return this.item.removeOnBackspace !== undefined ? this.item.removeOnBackspace : this.removeOnBackspace
    },
    addOnChangeComputed () {
      return this.item.addOnChange !== undefined ? this.item.addOnChange : this.addOnChange
    },
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    separatorList () {
      const enabled = this.item.enableSeparators !== undefined ? this.item.enableSeparators : this.enableSeparators
      if (enabled) {
        if (this.item.customSeparators && Array.isArray(this.item.customSeparators) && this.item.customSeparators.length > 0) {
          return this.item.customSeparators
        } else if (this.customSeparators.length > 0) {
          return this.customSeparators
        } else {
          return [' ', ',', ';']
        }
      }
      return []
    }
  },
  watch: {
    watch: {
      value: {
        immediate: true,
        handler (newVal) {
          if (Array.isArray(newVal)) {
            this.internalTags = [...newVal]
          } else if (typeof newVal === 'string') {
            // If the string contains commas, split it and trim each value.
            this.internalTags = newVal.includes(',') ? newVal.split(',').map(v => v.trim()) : newVal.length > 0 ? [newVal] : []
          } else {
            this.internalTags = []
          }
        }
      },
      internalTags (newTags) {
        this.$emit('input', newTags)
      }
    }
  },
  methods: {
    onInput (tags) {
      this.$emit('input', tags)
    }
  }
}
</script>

<style scoped>
/* Optional custom styling */
</style>
