<template>
  <div class="ps-tooltip-parent">

    <div
      :id="tooltipTargetId"
      class="d-flex"
      @mouseover="tooltipEnabled && canAccess('offer_details') && !offerDetails ? getBasicDetail() : ''"
    >
      <img :src="icon_url" alt="icon" width="30" class="mr-1" />
      <div class="offer-item">
        <div>
          <router-link
            v-if="canAccess('offer_details')"
            :to="{
            name: 'offer_details',
            params: { offerId: id }
          }"
          >
            {{ name }}
          </router-link>

          <span v-else>{{ name }}</span>
        </div>

        <div v-if="status !== null && visibility !== null" :class="`offer-status-visibility offer-status-${status} offer-visibility-${visibility}`"></div>
      </div>
    </div>

    <b-tooltip v-if="tooltipEnabled && canAccess('offer_details')" :target="tooltipTargetId" :variant="$store.state.appConfig.layout.skin" triggers="hover" placement="bottomright">
      <div class="width-350 font-small-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="card p-2 mb-0">
              <div class="d-flex justify-content-between">
                <b-skeleton width="30%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
              </div>
              <div class="d-flex justify-content-between">
                <b-skeleton width="60%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
              </div>
              <b-skeleton width="40%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>

              <b-skeleton class="mt-3" width="50%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
            </div>
          </template>

          <b-row
            v-if="offerDetails"
            class="card border border-dark p-2 mb-0 text-left"
            style="top: -5px;"
            :style="$store.state.appConfig.layout.skin === 'dark' ? 'border-color: white !important' : ''"
          >
            <b-col class="d-flex justify-content-between" :class="offerDetails.visibility ? 'mb-1' : 'mb-50'">
              <div class="offer-item">
                <div class="offer-id">
                  <span>{{ id }}</span>
                </div>
                <div v-if="offerDetails.status !== null && offerDetails.visibility !== null" :class="`offer-status-visibility offer-status-${offerDetails.status} offer-visibility-${offerDetails.visibility}`"></div>
              </div>

              <div><b>Created</b>: {{ offerDetails.created_at_timeago }} </div>
            </b-col>

            <b-col class="d-flex justify-content-between mb-1">
              <div class="offer-item">
                <img :src="icon_url" alt="icon" height="20" />
                <div class="offer-id">
                  <router-link
                    v-if="canAccess('offer_details')"
                    :to="{
                      name: 'offer_details',
                      params: { offerId: id }
                    }"
                  >
                    {{ name }}
                  </router-link>

                  <span v-else>{{ name }}</span>
                </div>
              </div>

              <div class="d-flex">
                <PlatformDeviceIcon v-for="platform in parseJson(offerDetails.platforms)" class="text-center mr-50" :platform="platform" :key="platform" />
              </div>
            </b-col>

            <b-col v-if="!offerDetails.visibility && offerDetails.invisibility_reasons" class="mb-1 text-warning text-capitalize">
              {{ JSON.parse(offerDetails.invisibility_reasons).join(', ') }}
            </b-col>

            <b-col class="mb-1">
              <div class="mb-50">
                <CountryList :country-list="offerDetails.country_list" :max-countries="3"/>
              </div>
            </b-col>

            <b-col>
              <b-row>
                <b-col cols="5" class="mb-50">
                  <OfferProviderElement :id="offerDetails.provider_id" :name="offerDetails.provider_name" />
                </b-col>
                <b-col cols="7" style="padding-left: 8px">
                  <OfferIap :has_iap_data="offerDetails.has_iap_data" />
                </b-col>
                <b-col cols="5" class="mb-50"><b>Revenue:</b></b-col>
                <b-col cols="7">
                  <b>{{ offerDetails.revenue_sum | formatCurrency }}</b>
                </b-col>

                <b-col cols="5" class="mb-50">Clicks:</b-col>
                <b-col cols="7">{{ offerDetails.clicks_count | formatCount }}</b-col>

                <b-col cols="5" class="mb-50">Events:</b-col>
                <b-col cols="7">{{ offerDetails.events_count | formatCount }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import CountryList from '@/views/resources/Offers/List/Parts/CountryList.vue'
import OfferProviderElement from '@/views/elements/OfferProviderElement.vue'
import PlatformDeviceIcon from '@/main/custom-components/PlatformDeviceIcon.vue'
import OfferIap from '@/views/resources/Offers/Parts/OfferIap.vue'

export default {
  components: {
    OfferIap,
    PlatformDeviceIcon,
    OfferProviderElement,
    CountryList
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    visibility: {
      type: Number
    },
    status: {
      type: Number
    },
    icon_url: {
      type: String
    },
    tooltipEnabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: true,
      offerDetails: null
    }
  },
  computed: {
    tooltipTargetId () {
      return `offer-tooltip-target-${this.id}-${this._uid}`
    }
  },
  watch: {
    id: {
      handler () {
        if (this.offerDetails) {
          this.loading = true
          this.offerDetails = null
        }
      }
    }
  },
  methods: {
    getBasicDetail () {
      this.sendActionLog(`get offer basic detail from ${this.$route.path} with offer id ${this.id}`)

      if (!this.offerDetails) {
        this.$http.get(
          `/api/offer/${  this.id  }/basic`
        ).then((response) => {
          this.offerDetails = response.data.data
          this.loading = false
        }).catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
      }
    }
  }
}
</script>
