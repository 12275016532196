import FormInput from '@/main/custom-components/DynamicForm/components/FormInput.vue'
import FormCheckbox from '@/main/custom-components/DynamicForm/components/FormCheckbox.vue'
import FormSelect from '@/main/custom-components/DynamicForm/components/FormSelect.vue'
import FormRadio from '@/main/custom-components/DynamicForm/components/FormRadio.vue'
import FormDate from '@/main/custom-components/DynamicForm/components/FormDate.vue'
import FormTextarea from '@/main/custom-components/DynamicForm/components/FormTextArea.vue'
import FormFile from '@/main/custom-components/DynamicForm/components/FormFile.vue'
import FormAutoSuggest from '@/main/custom-components/DynamicForm/components/FormAutoSuggest.vue'
import FormDateTime from '@/main/custom-components/DynamicForm/components/FormDateTime.vue'
import FormMultiSelect from '@/main/custom-components/DynamicForm/components/FormMultiSelect.vue'
import FormCheckboxYesNo from '@/main/custom-components/DynamicForm/components/FormCheckboxYesNo.vue'
import FormDateRange from '@/main/custom-components/DynamicForm/components/FormDateRange.vue'
import FormTags from '@/main/custom-components/DynamicForm/components/FormTags.vue'

export default {
  'form-input': FormInput,
  'form-checkbox': FormCheckbox,
  'form-select': FormSelect,
  'form-radio': FormRadio,
  'form-date': FormDate,
  'form-text': FormTextarea,
  'form-file': FormFile,
  'form-auto-suggest': FormAutoSuggest,
  'form-datetime': FormDateTime,
  'form-multiselect': FormMultiSelect,
  'form-checkbox_yes_no': FormCheckboxYesNo,
  'form-daterange': FormDateRange,
  'form-tags': FormTags
}