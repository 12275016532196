//Sub permission: check \App\Lib\AdminPermissions.php
import routerConfigs from '../router-configs/poRouterConfigs'

export default [
  {
    title: 'Prime Opinion',
    icon: 'CodesandboxIcon',
    children: [
      {
        url: routerConfigs.PO_ADJUST_EVENT_LOGS.path,
        title: 'Adjust Event Logs',
        icon: 'FileTextIcon',
        route: routerConfigs.PO_ADJUST_EVENT_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_ADJUST_EVENT_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_BOGDAN_BONUS.path,
        title: 'Bogdan Bonus',
        icon: 'PercentIcon',
        route: routerConfigs.PO_BOGDAN_BONUS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_BOGDAN_BONUS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_BONUS_CODES.path,
        title: 'Bonus Codes',
        icon: 'GiftIcon',
        route: routerConfigs.PO_BONUS_CODES.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_BONUS_CODES.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_BONUS_CODE_REDEMPTIONS.path,
        title: 'Bonus Code Redemptions',
        icon: 'DollarSignIcon',
        route: routerConfigs.PO_BONUS_CODE_REDEMPTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_BONUS_CODE_REDEMPTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_COHORT_ANALYSIS.path,
        title: 'Cohort Analysis',
        icon: 'LayoutIcon',
        route: routerConfigs.PO_COHORT_ANALYSIS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_COHORT_ANALYSIS.name,
            text: 'Main',
            main: true
          },
          {
            id: 'po_cohort_analysis_export',
            text: 'PO Cohort Analysis Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        url: routerConfigs.PO_AFFILIATE_PARTNERS.path,
        title: 'Affiliate Partners',
        icon: 'UserPlusIcon',
        route: routerConfigs.PO_AFFILIATE_PARTNERS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_AFFILIATE_PARTNERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_USERS.path,
        title: 'Users',
        icon: 'UsersIcon',
        route: routerConfigs.PO_USERS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_USERS.name,
            text: 'Main',
            main: true
          },
          {
            id: 'po_users_export',
            text: 'PO Users Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        title: 'PO User details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.PO_USER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_TRANSACTIONS.path,
        title: 'Transactions',
        icon: 'DatabaseIcon',
        route: routerConfigs.PO_TRANSACTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_TRANSACTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_CLAIMS.path,
        title: 'Claims',
        icon: 'CheckSquareIcon',
        route: routerConfigs.PO_CLAIMS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_CLAIMS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Config',
        icon: 'SettingsIcon',
        children: [
          {
            url: routerConfigs.PO_CONFIG_PAYOUT_CONFIRMATION.path,
            title: 'Payout Confirmation',
            icon: 'CreditCardIcon',
            route: routerConfigs.PO_CONFIG_PAYOUT_CONFIRMATION.name,
            sub_permissions: [
              {
                id: routerConfigs.PO_CONFIG_PAYOUT_CONFIRMATION.name,
                text: 'Main',
                main: true
              }
            ]
          }
        ]
      },
      {
        url: routerConfigs.PO_LEADERBOARDS.path,
        title: 'Leaderboards',
        icon: 'BarChart2Icon',
        route: routerConfigs.PO_LEADERBOARDS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_LEADERBOARDS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_LEVELS.path,
        title: 'Levels',
        icon: 'BarChartIcon',
        route: routerConfigs.PO_LEVELS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_LEVELS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_LOG_SPEND.path,
        title: 'Log Spend',
        icon: 'DollarSignIcon',
        route: routerConfigs.PO_LOG_SPEND.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_LOG_SPEND.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_SETTINGS.path,
        title: 'Settings',
        icon: 'SettingsIcon',
        route: routerConfigs.PO_SETTINGS.name,
        always_available_for_ids: [1, 5, 6],
        sub_permissions: [
          {
            id: routerConfigs.PO_SETTINGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_TRIGGERS.path,
        title: 'Triggers',
        icon: 'DiscIcon',
        route: routerConfigs.PO_TRIGGERS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_TRIGGERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_PERFORMANCE.path,
        title: 'Performance',
        icon: 'PieChartIcon',
        route: routerConfigs.PO_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_PERFORMANCE.name,
            text: 'Main',
            main: true
          },
          {
            id: routerConfigs.PO_PERFORMANCE_EXPORT.name,
            text: 'PO Performance Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        url: routerConfigs.PO_VALUE_REPORT.path,
        title: 'Value Report',
        icon: 'LayoutIcon',
        route: routerConfigs.PO_VALUE_REPORT.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_VALUE_REPORT.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_VIP_USERS.path,
        title: 'VIP Users',
        icon: 'AwardIcon',
        route: routerConfigs.PO_VIP_USERS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_VIP_USERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_EMAILS.path,
        title: 'E-Mails',
        icon: 'MailIcon',
        route: routerConfigs.PO_EMAILS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_EMAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_EMAIL_STATISTICS.path,
        title: 'E-Mail Statistics',
        icon: 'BarChart2Icon',
        route: routerConfigs.PO_EMAIL_STATISTICS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_EMAIL_STATISTICS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_REWARDS.path,
        title: 'Rewards',
        icon: 'AwardIcon',
        route: routerConfigs.PO_REWARDS.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_REWARDS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_OFFER_WALL_PERFORMANCE.path,
        title: 'Offerwall Performance',
        icon: 'CpuIcon',
        route: routerConfigs.PO_OFFER_WALL_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_OFFER_WALL_PERFORMANCE.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PO_ADJUST_CONFIG.path,
        title: 'Adjust Config',
        icon: 'CodesandboxIcon',
        route: routerConfigs.PO_ADJUST_CONFIG.name,
        sub_permissions: [
          {
            id: routerConfigs.PO_ADJUST_CONFIG.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  }
]
