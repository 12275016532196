<template>
  <div class="card base-table-header">
    <!-- Header Section -->
    <b-card-header v-show="!disableHeader">
      <slot name="tableHeader" />
      <BaseTableFilterBar
          v-model="filterValues"
          :filterSettings="filterSettings"
          :injectActions="injectActions"
          :firstSearch="firstSearch"
          :useQueryUrl="useQueryUrl"
          :isRowFilter="isRowFilter"
          :showImport="showImport"
          :showExport="showExport"
          :searchButtonText="searchButtonText"
          :dynamicFieldButtonAdd="dynamicFieldButtonAdd"
          :hideSearch="hideSearch"
          @search="search"
          @exportToCSV="exportToCSV"
          @changed="resetPage"
          @addDynamicField="addDynamicFields"
          @importFile="importFile"
          ref="filterBar"
      >
        <!-- Pass–through scoped slots for filters -->
        <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="props">
          <slot :name="slotName" v-bind="props" />
        </template>
      </BaseTableFilterBar>
      <slot name="additionalHeader" />
    </b-card-header>

    <!-- Table Section -->
    <div :style="computedTableWrapperStyles" :class="tableWrapperClasses" class="table-responsive mb-0">
      <table role="table" :aria-busy="isBusy" class="table b-table table-striped table-hover">
        <BaseTableHeader
            :fields="fields"
            :orderBy="params.orderBy"
            :orderDirection="params.orderDirection"
            @sort="sortCol"
        />
        <BaseTableBody
            :items.sync="displayedItems"
            :flattenedFields="flattenedFields"
            :itemsFormat="itemsFormat"
            :secondsToHMSReader="secondsToHMSReader"
            @cell-click="handleCellClick"
            @update-cell="updateCell"
            @selectedRow="$emit('selectedRow', $event)"
        >
          <!-- Pass–through scoped slots for table body -->
          <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="props">
            <slot :name="slotName" v-bind="props" />
          </template>
        </BaseTableBody>
      </table>
    </div>

    <!-- Footer Section -->
    <b-card-footer>
      <div v-if="isLoadingSpinner" class="text-center">
        <b-spinner variant="primary" label="Spinning" class="m-auto" />
      </div>
      <slot name="additionalFooter" />
      <b-row>
        <b-col
            cols="12"
            v-show="!displayedItems.length"
            style="font-weight: 500; text-align: center"
        >
          No data
        </b-col>
        <b-col
            cols="12"
            v-if="showPagination && (displayedItems.length || (paginationData && paginationData.is_simple_paginate))"
        >
          <pagination-and-table-info
              :data="paginationData"
              :useQueryUrl="useQueryUrl"
              @change="handleChangePage"
          />
        </b-col>
      </b-row>
    </b-card-footer>
  </div>
</template>

<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'
import { secondsToHMSReader } from '@/utils/helpers'
import BaseTableFilterBar from '@/main/custom-components/BaseTable/BaseTableFilterBar.vue'
import BaseTableHeader from '@/main/custom-components/BaseTable/BaseTableHeader.vue'
import BaseTableBody from '@/main/custom-components/BaseTable/BaseTableBody.vue'

export default {
  name: 'NewBaseTable',
  components: {
    BaseTableBody,
    BaseTableHeader,
    BaseTableFilterBar,
    PaginationAndTableInfo
  },
  props: {
    fields: { type: Array, required: true },
    itemsFormat: { type: String, default: '' },
    filterSettings: { type: [Array, Object], default: () => ({}) },
    isRowFilter: { type: Boolean, default: false },
    injectActions: { type: Object, default: () => ({}) },
    itemPerPage: { type: Number, default: 50 },
    defaultSortBy: { type: String, default: 'id' },
    defaultSortDirection: { type: String, default: 'desc' },
    method: { type: String, default: 'get' },
    url: { type: String, required: true },
    showSpinner: { type: Boolean, default: true },
    disableHeader: { type: Boolean, default: false },
    overflowVisible: { type: Boolean, default: false },
    overflowXScroll: { type: Boolean, default: false },
    firstSearch: { type: Boolean, default: true },
    hideSearch: { type: Boolean, default: false },
    customKeyDataPagination: { type: [String, Boolean], default: false },
    isFrontendPagination: { type: Boolean, default: false },
    useQueryUrl: { type: Boolean, default: true },
    indexDynamicField: { type: [Boolean, Number], default: false },
    dynamicFields: { type: Object, required: false },
    dynamicFieldButtonAdd: { type: String, default: 'Add Another' },
    isInitOnlyGetFilter: { type: Boolean, default: false },
    showImport: { type: Boolean, default: false },
    searchButtonText: { type: String, default: 'Search' },
    stickyHeader: { type: Boolean, default: false },
    showExport: { type: Boolean, default: false },
    exportUrl: { type: String, default: '' },
    exportName: { type: String, default: 'export' },
    wrapperOverflowY: { type: String, default: '' },
    useSpinnerLoading: { type: Boolean, default: false },
    showPagination: { type: Boolean, default: true }
  },
  data () {
    return {
      isBusy: false,
      isLoadingSpinner: false,
      // Pagination and sorting parameters
      params: {
        page: 1,
        orderBy: this.useQueryUrl ? (this.$route.query.orderBy ?? this.defaultSortBy) : this.defaultSortBy,
        itemPerPage: this.itemPerPage,
        orderDirection: this.$route.query.orderDirection ?? this.defaultSortDirection,
        initFilter: 0
      },
      // For backend pagination:
      items: [],
      // For frontend pagination:
      allItems: [],
      responseData: null,
      filterValues: {},
      onlyGetFilterHasApplied: false
    }
  },
  watch: {
    onlyGetFilterHasApplied (value) {
      if (value) {
        this.$emit('onlyGetFilterHasApplied')
      }
    }
  },
  computed: {
    tableWrapperClasses () {
      return {
        'overflow-visible': this.overflowVisible,
        'overflow-x-scroll': this.overflowXScroll,
        'b-table-sticky-header': this.stickyHeader
      }
    },
    computedTableWrapperStyles () {
      const styles = {}
      if (this.stickyHeader) {
        styles.maxHeight = '80vh'
        styles.overflowY = 'auto'
      }
      if (this.wrapperOverflowY) {
        styles.overflowY = this.wrapperOverflowY
      }
      return styles
    },
    isMultipleHeader () {
      return Array.isArray(this.fields[0])
    },
    flattenedFields () {
      if (this.isMultipleHeader) {
        const addToEnd = []
        const flattened = this.fields.reduce((acc, row) => {
          row.forEach(field => {
            if (field?.addToEnd) {
              addToEnd.push(field)
            } else if (!field.colspan) acc.push(field)
          })
          return acc
        }, [])
        flattened.push(...addToEnd)
        return flattened
      }
      return this.fields
    },
    sortedItems () {
      // Ensure allItems is an array
      const items = Array.isArray(this.allItems) ? this.allItems : []
      // If not using frontend pagination or there are no items, return the items array (or an empty array)
      if (!this.isFrontendPagination || !items.length) {
        return items
      }
      const { orderBy, orderDirection } = this.params
      const direction = orderDirection === 'asc' ? 1 : -1
      return [...items].sort((a, b) => {
        const aVal = a[orderBy]
        const bVal = b[orderBy]
        // Numeric comparison:
        if (!isNaN(parseFloat(aVal)) && !isNaN(parseFloat(bVal))) {
          return direction * (parseFloat(aVal) - parseFloat(bVal))
        }
        // Date comparison:
        if (!isNaN(Date.parse(aVal)) && !isNaN(Date.parse(bVal))) {
          return direction * (new Date(aVal) - new Date(bVal))
        }
        // If aVal or bVal is null/undefined, treat it as an empty string (or handle it as needed)
        // Fallback to string comparison:
        const safeAVal = aVal != null ? aVal.toString() : ''
        const safeBVal = bVal != null ? bVal.toString() : ''
        return direction * safeAVal.localeCompare(safeBVal, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })
    },
    displayedItems () {
      if (this.isFrontendPagination) {
        if (this.showPagination) {
          const start = (this.params.page - 1) * this.params.itemPerPage
          return this.sortedItems.slice(start, start + this.params.itemPerPage)
        } else {
          return this.sortedItems
        }
      }

      return this.items
    },
    paginationData () {
      if (this.isFrontendPagination) {
        const total = this.allItems?.length ?? 0
        const last_page = Math.ceil(total / this.params.itemPerPage)
        return this.laravelPaginationPages({
          total,
          last_page,
          from: (this.params.page - 1) * this.params.itemPerPage,
          to: (this.params.page - 1) * this.params.itemPerPage + this.params.itemPerPage,
          current_page: this.params.page,
          all_items: this.allItems
        })
      }
      if (this.customKeyDataPagination && this.responseData) {
        return this.laravelPaginationPages(this.responseData[this.customKeyDataPagination])
      }
      return this.laravelPaginationPages(this.responseData)
    }
  },
  methods: {
    secondsToHMSReader,
    resetPage () {
      this.params.page = 1
    },
    showLoadingSpinner () {
      this.isLoadingSpinner = true
    },
    hideLoadingSpinner () {
      this.isLoadingSpinner = false
    },
    handleCellClick ({ field, row }) {
      if (field.editable) {
        this.$emit('editField', { fieldKey: field.key, row })
      }
      this.$emit(`selectedCol${field.key}`, row[field.key])
    },
    updateCell (cellData) {
      const index = this.isFrontendPagination ? (this.params.page - 1) * this.params.itemPerPage + cellData.rowIndex : cellData.rowIndex
      if (this.isFrontendPagination) {
        this.allItems[index][cellData.field.key] = cellData.value
      } else {
        this.items[cellData.rowIndex][cellData.field.key] = cellData.value
      }
    },
    sortCol (fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'desc'
      }
      if (this.useQueryUrl) this.setParamsOrderToUrl()
      if (!this.isFrontendPagination) this.search()
    },
    setParamsOrderToUrl () {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set('orderBy', this.params.orderBy)
      queryParams.set('orderDirection', this.params.orderDirection)
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`)
    },
    generateParamsPaginationByUrl () {
      const queryParams = new URLSearchParams(window.location.search)
      const page = queryParams.get('page')
      if (page) this.params.page = parseInt(page)
    },
    generateParamsOrderByUrl () {
      const queryParams = new URLSearchParams(window.location.search)
      const sortBy = queryParams.get('sortBy')
      if (sortBy) this.params.orderBy = sortBy
      const sortDirection = queryParams.get('sortDirection')
      if (sortDirection) this.params.orderDirection = sortDirection
    },
    search (isSilentSearch = false) {
      const hasFileInput = Object.values(this.filterValues).some(val => val instanceof File)
      if (this.useQueryUrl) {
        this.$refs.filterBar?.$refs?.filter?.generateFilterValuesByUrl()
        this.generateParamsPaginationByUrl()
        this.generateParamsOrderByUrl()
      }
      if (hasFileInput) {
        this.searchWithFile()
        return
      }
      if (!isSilentSearch) {
        this.isBusy = true
        if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
        if (this.useSpinnerLoading) this.showLoadingSpinner()
      }
      let getParams = { ...this.params, ...this.filterValues }
      if (this.isInitOnlyGetFilter && !this.onlyGetFilterHasApplied) {
        getParams.onlyGetFilter = true
      }
      if (this.indexDynamicField) {
        getParams.dynamicFieldCount = this.filterSettings[this.indexDynamicField].length - 1
      }
      if (typeof this.injectActions.modifyParams === 'function') {
        getParams = this.injectActions.modifyParams(getParams)
      }
      const requestData = this.method === 'get' ? { params: getParams } : getParams
      this.$http[this.method](this.url, requestData)
        .then(this.handleResponse)
        .catch(this.handleErrors)
    },
    searchWithFile () {
      this.isBusy = true
      if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
      if (this.useSpinnerLoading) this.showLoadingSpinner()
      const formData = new FormData()
      Object.entries(this.filterValues).forEach(([key, value]) => {
        if (value instanceof File) formData.append(key, value)
      })
      const mergedParams = { ...this.params, ...this.filterValues }
      Object.entries(mergedParams).forEach(([key, value]) => formData.append(key, value))
      this.$http.post(`${this.url}/file`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(this.handleResponse)
        .catch(this.handleErrors)
    },
    importFile () {
      this.isBusy = true
      if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
      if (this.useSpinnerLoading) this.showLoadingSpinner()
      const formData = new FormData()
      Object.entries(this.filterValues).forEach(([key, value]) => {
        // if (value instanceof File) {
        formData.append(key, value)
        // }
      })
      this.$http.post(`${this.url}/import`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(response => {
          this.hideSpinnerAndLoading()
          this.$emit('beforeShowingData', { filterValues: this.filterValues })
          this.filterValues.file = null
          this.search()
          const count = response?.data?.length
          this.alertSuccess(count ? `${count} data has been imported` : 'Data has been imported')
          this.isBusy = false
        })
        .catch(this.handleErrors)
    },
    handleResponse (response) {
      this.hideSpinnerAndLoading()
      this.onlyGetFilterHasApplied = true
      this.$emit('beforeShowingData', { filterValues: this.filterValues })
      const responseData = response.data

      if (responseData.data) {
        if (this.isFrontendPagination) {
          this.allItems = (responseData.data &&
              typeof responseData.data === 'object' &&
              !Array.isArray(responseData.data)) ? Object.values(responseData.data) : responseData.data
        } else {
          this.items = (responseData.data &&
              typeof responseData.data === 'object' &&
              !Array.isArray(responseData.data)) ? Object.values(responseData.data) : responseData.data
        }
      }

      this.responseData = responseData
      this.initiateFilters(responseData)
      this.$emit('updateStatistic', responseData.statistic_data)
      this.$emit('afterShowingData', responseData)
      this.isBusy = false
    },
    handleErrors (errors) {
      const errorMsg =
          errors.response && errors.response.data ? (errors.response.data.errors || errors.response.data) : errors.message
      this.alertError(errorMsg)
      this.hideSpinnerAndLoading()
      this.isBusy = false
    },
    handleChangePage (page) {
      this.params.page = page
      this.sendActionLog(`change pagination ${this.$route.path} with params ${JSON.stringify(this.params)}`)

      if (this.isFrontendPagination) {
        if (this.showPagination) {
          this.items = this.allItems.slice(this.paginationData.from, this.paginationData.to)
        } else {
          this.items = this.allItems
        }
      } else {
        this.search()
      }
    },
    addDynamicFields () {
      const newFields = Object.entries(this.dynamicFields).map(([key, value]) => {
        const newNum = this.filterSettings[this.indexDynamicField].length
        const newKeyName = key.replace(/\d+/, newNum)
        const items = Object.assign({}, value)

        items.defaultValue = ''
        items.data = []

        if (value.customGenerateSuggestion) {
          items.customGenerateSuggestion = value.customGenerateSuggestion
        }

        if (value.change) {
          items.change = value.change
        }

        return [newKeyName, items]
      })

      this.filterSettings[this.indexDynamicField] = [
        ...this.filterSettings[this.indexDynamicField],
        Object.fromEntries(newFields)
      ]

      this.$forceUpdate()
    },
    async exportToCSV () {
      this.isBusy = true
      if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
      if (this.useSpinnerLoading) this.showLoadingSpinner()

      // Check if the method is 'post', and structure the request accordingly
      // eslint-disable-next-line multiline-ternary
      const requestData = this.method === 'post'
      // eslint-disable-next-line multiline-ternary
        ? {
          // POST request sends the data directly (without 'body' or 'params' keys)
          ...this.params,
          ...this.filterValues,
          only_file_name: true
        }
        : {
          // GET request sends the data as URL parameters
          params: {
            ...this.params,
            ...this.filterValues,
            only_file_name: true
          }
        }

      if (this.exportUrl) {
        const response = await this.$http[this.method](this.exportUrl, {
          ...this.filterValues,
          responseType: 'blob' // Ensure the response is treated as a blob (binary data)
        })

        if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
        if (this.useSpinnerLoading) this.hideLoadingSpinner()

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], { type: 'text/csv' })

        // Create a URL for the blob object
        const downloadUrl = window.URL.createObjectURL(blob)

        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `${this.exportName}.csv`) // Set the file name

        // Append the link to the document and trigger the click event
        document.body.appendChild(link)
        link.click()

        // Clean up the URL and remove the link element
        link.remove()
        window.URL.revokeObjectURL(downloadUrl)

        this.isBusy = false
      } else {
        this.$http[this.method](`${this.url}/export`, requestData)
          .then((response) => {
            const filename = response.data
            const dynamicFieldCount = Math.max((this.filterSettings?.[this.indexDynamicField]?.length ?? 0) - 1, 0)

            // For the second request to fetch the file
            // eslint-disable-next-line multiline-ternary
            const secondRequestData = this.method === 'post'
            // eslint-disable-next-line multiline-ternary
              ? {
                // POST request sends the data directly
                ...this.params,
                ...this.filterValues,
                fields: this.flattenedFields,
                dynamicFieldCount
              }
              : {
                // GET request sends the data as URL parameters
                params: {
                  ...this.params,
                  ...this.filterValues,
                  fields: this.flattenedFields,
                  dynamicFieldCount
                }
              }

            this.$http[this.method](`${this.url}/export`, {
              ...secondRequestData,
              responseType: 'blob' // Expecting a file blob
            })
              .then((response) => {
                if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
                if (this.useSpinnerLoading) this.hideLoadingSpinner()

                // Create a downloadable link for the exported file
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename) // Filename from the first response
                document.body.appendChild(link)
                link.click() // Trigger download

                this.isBusy = false
              })
              .catch((errors) => {
                this.handleExportErrors(errors)
              })
          })
          .catch((errors) => {
            this.handleExportErrors(errors)
          })
      }
    },
    hideSpinnerAndLoading () {
      if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
      if (this.useSpinnerLoading) this.hideLoadingSpinner()
    },
    initiateFilters (responseData) {
      if (this.params.initFilter === 0 && responseData.filterData) {
        const filterSettingsArray = Array.isArray(this.filterSettings) ? this.filterSettings : [this.filterSettings]
        Object.keys(responseData.filterData).forEach(property => {
          filterSettingsArray.forEach(setting => {
            if (setting[property]) {
              if (
                Array.isArray(setting[property].data) &&
                  setting[property].defaultData !== undefined
              ) {
                setting[property].data = [setting[property].defaultData, ...responseData.filterData[property]]
              } else {
                setting[property].data = responseData.filterData[property]
              }
              const { defaultValue = null } = setting[property] || {}
              if (Array.isArray(defaultValue) && defaultValue.includes('all')) {
                setting[property].defaultValue = responseData.filterData[property].map(item => item.value)
              }
            }
          })
        })
        this.params.initFilter = 1
        if (this.$refs.filterBar.$refs.filter && !responseData.filterData.isAdditionalFilter) {
          this.$refs.filterBar.$refs.filter.generateModels?.()
        }
      }
    },
    setSortField (fieldName) {
      this.params.orderBy = fieldName
    },
    handleExportErrors (errors) {
      if (typeof errors.response === 'object') {
        if (typeof errors.response.data.errors === 'object') {
          this.alertError(errors.response.data.errors)
        } else {
          this.alertError(errors.response.data)
        }
      } else {
        this.alertError(errors.message)
      }

      if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
      if (this.useSpinnerLoading) this.hideLoadingSpinner()
      this.isBusy = false
    }
  },
  created () {
    Object.keys(this.filterSettings).forEach(property => {
      if (
        Array.isArray(this.filterSettings[property].data) &&
          this.filterSettings[property].defaultData !== undefined
      ) {
        this.filterSettings[property].data.push(this.filterSettings[property].defaultData)
      }
    })
    if (!Object.keys(this.filterSettings).length) this.search()
  }
}
</script>

<style lang="scss">

[dir] .table-striped tbody tr:not(:hover):nth-of-type(even) .position-sticky:not(:hover) {
  background-color: white;
  z-index: 2;
}

[dir] .table-striped tbody tr:not(:hover):nth-of-type(odd) .position-sticky:not(:hover) {
  background-color: #fafafc;
  z-index: 2;
}

[dir] .table-striped tbody tr:hover .position-sticky {
  background: inherit;
}

.table-column-sticky {
  .table-responsive {
    overflow-y: hidden;

    table {
      overflow-y: hidden;
    }
  }

  td {
    position: relative;
  }

  tr:not(td.position-sticky):hover {
    background-color: rgb(226 226 224 / 50%) !important;

    td:not(td.position-sticky) {
      z-index: 1;
    }

    td.position-sticky {
      background-color: rgb(214 214 213 / 100%) !important;
      z-index: 2;
      width: 100%;
    }
  }

  td:not(.position-sticky):hover {
    &:not(.is-gradient-color)::after {
      background-color: rgb(226 226 224 / 30%);
      content: '\00a0';
      height: 200vh;
      left: 0;
      position: absolute;
      top: -100vh;
      width: 100%;
      pointer-events: none;
    }
  }
}

/* Dark Mode Styles */
.dark-layout .table-column-sticky tbody tr:not(:hover):nth-of-type(even) .position-sticky:not(:hover) {
  background-color: rgb(36, 43, 61);
  z-index: 2;
}

.dark-layout .table-column-sticky tbody tr:not(:hover):nth-of-type(odd) .position-sticky:not(:hover) {
  background-color: #242b3d;
  z-index: 2;
}

.dark-layout .table-column-sticky tr:not(td.position-sticky):hover {
  background-color: rgba(0, 0, 0, 0.5) !important;

  td:not(td.position-sticky) {
    z-index: 1;
  }

  td.position-sticky {
    background-color: rgba(0, 0, 0, 1) !important;
    z-index: 2;
    width: 100%;
  }
}

.dark-layout .table-column-sticky td:not(.position-sticky):hover:not(.is-gradient-color)::after {
  background-color: rgba(0, 0, 0, 0.33);
  content: '\00a0';
  height: 200vh;
  left: 0;
  position: absolute;
  top: -100vh;
  width: 100%;
  pointer-events: none;
}

.base-table-header .card-header {
  padding-bottom: 0.5rem;
}
thead tr.border th {
  border: 1px solid #dae1e7 !important;
}

.right-divider {
  box-shadow: inset -3px 0 1px -1px #000;
}
.left-divider {
  box-shadow: inset 3px 0 1px -1px #000;
}

</style>

