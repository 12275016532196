<template>
  <div v-if="!item.hidden" :style="item.wrapperStyle || ''">
    <component
        :is="formComponent"
        :item.sync="item"
        :value.sync="models[filterKey]"
        :filterKey="filterKey"
        :suggestions.sync="suggestions"
        :models.sync="models"
        @input="handleInput"
        @change="handleChange"
        @find="handleFind"
        v-bind="$attrs"
        v-on="listeners"
    >
      <template v-for="(_, slot) in $scopedSlots" #[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </component>
  </div>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import componentMap from '@/utils/formDynamicComponentMap'

export default {
  name: 'DynamicFormElement',
  mixins: [formMixin],
  components: componentMap,
  props: {
    item: {
      type: Object,
      required: true
      // validator: val => val.type && val.hidden !== undefined
    },
    filterKey: {
      type: String,
      required: true
    },
    models: {
      required: true
    }
  },
  computed: {
    formComponent () {
      return componentMap[`form-${this.item.type}`] || null
    },
    listeners () {
      return {
        ...this.$listeners,
        search: () => this.$emit('search')
      }
    }
  },
  data () {
    return {
      suggestions: this.item.suggestions ?? {}
    }
  },
  methods: {
    handleInput (value, filterKey) {
      this.$emit('update:models', {
        ...this.models,
        [filterKey ?? this.filterKey]: value
      })
      if (this.item.input) {
        this.item.input(value, this.getSafeContext())
      }
    },
    handleChange (value, filterKey) {
      this.$emit('model-change', { value, context: this.getSafeContext() })
      if (this.item.change && !filterKey) {
        this.item.change(value, this.getSafeContext())
      }
    },
    async handleFind (query) {
      if (this.item.customGenerateSuggestion) {
        const suggestions = await this.item.customGenerateSuggestion(query, this.getSafeContext())

        if (suggestions.length > 0) {
          this.$set(this, 'suggestions', {
            ...this.suggestions,
            [this.filterKey]: [{ data: suggestions }]
          })
        }
      }
    },
    getSafeContext () {
      return {
        filterKey: this.filterKey,
        formData: this.models,
        suggestions: this.suggestions,
        refresh: () => this.$forceUpdate(),
        forceRefresh: (object, filterKey) => {
          this.refreshFilterElement(object, filterKey ?? this.filterKey)
          this.$forceUpdate()
        },
        search: () => this.$emit('search')
      }
    }
  }
}
</script>

<style lang="scss">
.form-row > div > .multiselect {
  margin-top: 0 !important;
}
</style>
