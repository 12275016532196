<template>
  <div>
    <!-- Conditionally show summary or country flags -->
    <div v-if="countries.length > maxCountries" class="text-primary cursor-pointer" @click="showModal">
      {{ countries.length }} countries
    </div>
    <div v-else class="d-flex" style="gap: 5px; margin-left: -0.5em;">
      <country-flag
          v-for="countryCode in countries"
          :country="countryCode"
          size="normal"
          :key="countryCode"
      />
    </div>

    <!-- Modal for displaying all countries -->
    <b-modal ref="countriesModal" hide-footer title="Countries">
      <div>
        <country-flag
            v-for="countryCode in countries"
            :country="countryCode"
            size="normal"
            :key="countryCode"
            class="mb-25"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'CountryList',
  props: {
    countryList: {
      type: String,
      required: true
    },
    maxCountries: {
      type: Number,
      default: 5 // Default to show a summary if more than 5 countries
    }
  },
  computed: {
    countries () {
      return this.countryList.split(',')
    }
  },
  methods: {
    showModal () {
      this.$refs.countriesModal.show()
    }
  }
}
</script>

<style scoped>
.mb-25 {
  margin-bottom: 0.25rem;
}
</style>
