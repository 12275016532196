<template>
  <multiselect
      :style="{ width: computedWidth }"
      :class="classInput ? classInput : 'my-1'"
      v-model="localSelectedOptions"
      :track-by="trackBy"
      :label="label"
      :loading="isLoading"
      :options="sortedOptions"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :placeholder="placeholderText"
      :show-labels="false"
      :internal-search="searchUrl === ''"
      @search-change="searchUrl !== '' && updateSearch($event)"
  >
    <template v-slot:selection="{ values, search, isOpen }">
      <span class="multiselect__single text-nowrap" v-if="values.length" v-show="!isOpen">
        <span v-if="values.length === 1">
            <slot name="multi-select__one_selection" :item="values[0]">
                {{ values[0][label] }}
            </slot>
        </span>
        <span v-else>{{ values.length }} {{ labelSelectedText }}</span>
      </span>
    </template>
    <template v-slot:tag="{ option, remove }">
      <span class="custom__tag d-flex">
        <slot name="multi-select__selected" :option="option">
          <span>{{ option[trackBy] }}</span>
        </slot>
        <span class="custom__remove ml-25 cursor-pointer" @click.stop="remove(option)">❌</span>
      </span>
    </template>
    <template v-slot:option="data">
      <div class="option__desc d-flex align-items-center">
        <!-- Use the helper method to check selection status -->
        <FeatherIcon
            v-if="isOptionSelected(data.option)"
            icon="CheckIcon"
            size="16"
            class="bg-primary text-white rounded p-25"
        />
        <FeatherIcon
            v-else
            icon="SquareIcon"
            size="16"
        />
        <slot name="multi-select__text" :option="data.option">
          <span class="ml-50 option__title">{{ data.option }}</span>
        </slot>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiSelectForm',
  components: {
    Multiselect
  },
  props: {
    defaultOptions: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    placeholderText: {
      type: String,
      default: ''
    },
    labelSelectedText: {
      type: String,
      default: 'items selected'
    },
    searchUrl: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    classInput: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      options: [...this.defaultOptions]
    }
  },
  watch: {
    search (query) {
      this.searchOptionsViaAPI(query)
    },
    defaultOptions (newOptions) {
      this.options = [...newOptions]
    }
  },
  computed: {
    localSelectedOptions: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    computedWidth () {
      // For now, always 100%
      return '100%'
    },
    sortedOptions () {
      // Move selected options to the top using trackBy key
      const selectedSet = new Set(
        this.localSelectedOptions.map(option => option[this.trackBy])
      )
      const selectedOptions = this.options.filter(option => selectedSet.has(option[this.trackBy])
      )
      const unselectedOptions = this.options.filter(option => !selectedSet.has(option[this.trackBy])
      )
      return [...selectedOptions, ...unselectedOptions]
    }
  },
  methods: {
    updateSearch (query) {
      this.search = query
    },
    async searchOptionsViaAPI (query) {
      this.isLoading = true
      try {
        const res = await this.$http.get(this.searchUrl, {
          params: {
            search: query,
            itemPerPage: 20
          }
        })
        // Fetch new options
        const newOptions = res.data.data
        // Merge: filter out new options already selected (using trackBy key)
        const existingOptionIds = new Set(
          this.localSelectedOptions.map(option => option[this.trackBy])
        )
        this.options = [
          ...newOptions.filter(option => !existingOptionIds.has(option[this.trackBy])),
          ...this.localSelectedOptions
        ]
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        this.isLoading = false
      }
    },
    // Helper method to determine if an option is selected based on trackBy
    isOptionSelected (option) {
      return this.localSelectedOptions.some(
        selected => selected[this.trackBy] === option[this.trackBy]
      )
    }
  }
}
</script>

<style lang="scss">
@import "../../@core/scss/vue/libs/vue-multiselect.scss";

// Dark layout overrides
.dark-layout {
  .multiselect__tags {
    border-color: rgba(82, 90, 114, 0.57);
  }
  .multiselect__single {
    color: #dddddd !important;
  }
  .multiselect__tags-wrap {
    color: white;
  }
}
</style>
