import { render, staticRenderFns } from "./DBViewModal.vue?vue&type=template&id=fa828faa&scoped=true"
import script from "./DBViewModal.vue?vue&type=script&lang=js"
export * from "./DBViewModal.vue?vue&type=script&lang=js"
import style0 from "./DBViewModal.vue?vue&type=style&index=0&id=fa828faa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa828faa",
  null
  
)

export default component.exports