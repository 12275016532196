<template>
  <div class="modal-header" style="display: block;">
    <b-card
      v-for="(offer) in data"
      :key="offer.id"
      class="mb-2"
    >
      <div class="d-flex">
        <div class="mr-1">
          <OfferElement
            :id="offer.id"
            :name="offer.name"
            :status="offer.status"
            :visibility="offer.visibility"
            :icon_url="offer.icon_url"
            class="mb-1"
          />
          <div class="d-table">
            <!-- Started -->
            <div class="mb-2">
              <strong>Started:</strong>
              {{ offer.started_at | dotDateTimeShort }}
            </div>

            <!-- Publisher Payout -->
            <div class="d-flex mb-2 align-items-center">
              <div class="text-right" style="width: 103px;">
                <strong>Publisher Payout:</strong>
              </div>
              <div class="ml-2">
                <div :class="['badge-box', getPayoutClass(offer.publisher_payout_sum)]">
                  {{ offer.publisher_payout_sum | formatCurrency }}
                </div>
              </div>
            </div>

            <!-- IAPs -->
            <div class="d-flex mb-2 align-items-center">
              <div class="text-right" style="width: 103px;">
                <strong>IAPs:</strong>
              </div>
              <div class="ml-2">
                <div
                  v-if="!!offer.has_iap_data"
                  class="badge-box badge-orange cursor-pointer"
                  @click="showIapDataModal(offer.id, offer.user_id)"
                >
                  {{ offer.transaction_value_sum | formatCurrency }}
                </div>
                <div v-else class="badge-box badge-iap-empty"> </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Table of events -->
        <b-table
          :items="offer.events"
          :fields="fields"
          small
          responsive
        >
          <template #cell(reward_name)="data">
            <div class="d-flex align-items-center text-nowrap" style="width: 100px">
              {{ data.item.reward_name }} ({{ data.item.reward_id }})
              <b-badge
                v-if="data.item.is_high_risk"
                variant="danger"
                class="ml-1"
              >
                High Risk
              </b-badge>
            </div>
          </template>

          <template #cell(created_at)="data">
            <span class="text-nowrap">{{ data.item.created_at | dotDateTimeShort }}</span>
          </template>

          <template #cell(delay)="data">
            {{ data.item.delay }}
          </template>

          <template #cell(revenue)="data">
            {{ data.item.revenue | formatCurrency }}
          </template>

          <template #cell(publisher_payout)="data">
            <b-badge :class="getPayoutClass(data.item.publisher_payout)">
              {{ data.item.publisher_payout | formatCurrency }}
            </b-badge>
          </template>

          <template #cell(status)="data">
            <div class="d-flex align-items-center text-nowrap">
              <b-badge
                :variant="getStatusVariant(data.item.status)"
                class="text-white"
              >
                {{ getStatusText(data.item.status) }}
              </b-badge>
            </div>
          </template>

          <template #cell(credit_status)="data">
            <span
              :class="[getCreditStatusClass(data.item.credit_status, data.item.status), { 'cursor-pointer': canConfirmCedit }]"
              v-b-tooltip.hover="getCreditTooltip(data.item.credit_status, data.item.status, data.item.credit_date)"
              @click="handleCreditStatusClick(data.item)"
            >
              {{ getCreditStatusText(data.item.credit_status, data.item.status) }}
            </span>
          </template>
        </b-table>
      </div>
    </b-card>

    <!-- IAP Data Modal -->
    <b-modal id="iap-data-modal" ref="iapDataModal" title="IAP Data" hide-footer>
      <b-table
        :items="iapData"
        :fields="iapFields"
        small
        responsive
      >
        <template #cell(transaction_value)="data">
          {{ data.item.transaction_value | formatCurrency }}
        </template>
        <template #cell(transaction_time)="data">
          {{ data.item.transaction_time | dotDateTimeShort }}
        </template>
        <template #cell(provider_id)="data">
          <OfferProviderElement :id="data.item.provider_id" :name="data.item.provider_name" />
        </template>
      </b-table>
    </b-modal>

    <b-modal id="credit-now-modal" ref="creditNowModal" title="Credit Now?" @ok="confirmCreditUpdate">
      <p>Are you sure you want to credit this now?</p>
    </b-modal>
  </div>
</template>

<script>
import OfferElement from '@/views/resources/Offers/Parts/OfferElement.vue'
import OfferProviderElement from '@/views/elements/OfferProviderElement.vue'

export default {
  name: 'UserOffersOverview',
  components: {OfferProviderElement, OfferElement },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fields: [
        { key: 'reward_name', label: 'Reward' },
        { key: 'created_at', label: 'Completed' },
        { key: 'delay', label: 'Delay' },
        { key: 'revenue', label: 'Revenue' },
        { key: 'publisher_payout', label: 'Payout' },
        { key: 'status', label: 'Status' },
        { key: 'credit_status', label: 'Credit Status' }
      ],
      iapFields: [
        { key: 'transaction_value', label: 'Transaction Value' },
        { key: 'transaction_time', label: 'Transaction Time' },
        { key: 'provider_id', label: 'Provider' }
      ],
      selectedEvent: null,
      iapData: []
    }
  },
  computed: {
    canConfirmCedit () {
      const currentUserId = this.$store.state.auth.AppActiveAdmin.id
      return [1, 15, 26, 50].includes(currentUserId)
    }
  },
  methods: {
    getStatusVariant (status) {
      switch (status) {
      case 1:
        return 'warning'
      case 2:
        return 'success'
      case 3:
        return 'danger'
      default:
        return 'secondary'
      }
    },
    getStatusText (status) {
      switch (status) {
      case 1:
        return 'Pending'
      case 2:
        return 'Completed'
      case 3:
        return 'Reconciled'
      default:
        return 'Unknown'
      }
    },
    getPayoutClass (payout) {
      if (payout < 0.30) return 'badge-light-blue'
      if (payout <= 3.50) return 'badge-medium-blue'
      return 'badge-dark-blue'
    },
    getCreditStatusClass (creditStatus, status) {
      if (creditStatus === 1) return 'text-success'
      if (creditStatus === 0 && status !== 2) return 'text-danger'
      if (creditStatus === 0 && status === 2) return 'text-warning'
      return 'text-secondary'
    },
    getCreditStatusText (creditStatus, status) {
      if (creditStatus === 1) return 'Credited'
      if (creditStatus === 0 && status !== 2) return 'Rejected'
      if (creditStatus === 0 && status === 2) return 'Pending'
      return 'Unknown'
    },
    getCreditTooltip (creditStatus, status, creditDate) {
      if (creditStatus === 0 && status === 2) {
        return `Credit date: ${creditDate || 'N/A'}`
      }
      return ''
    },
    handleCreditStatusClick (row) {
      if (this.canConfirmCedit && row.credit_status === 0 && row.status === 2) {
        this.selectedEvent = row
        this.$refs.creditNowModal.show()
      }
    },
    confirmCreditUpdate () {
      if (this.selectedEvent) {
        this.$http.post(`/api/offer-events/${this.selectedEvent.id}/credit-now`)
          .then(response => {
            const { credit_date } = response.data
            if (credit_date) {
              this.selectedEvent.credit_date = credit_date
              this.alertSuccess('Credit updated successfully')
            }
            this.$refs.creditNowModal.hide()
          })
          .catch(() => {
            this.alertError('Failed to update credit.')
          })
      }
    },
    showIapDataModal (offerId, userId) {
      this.$http
        .get(`/api/user/${userId}/iap-data/${offerId}`)
        .then(response => {
          this.iapData = response.data.data
          this.$refs.iapDataModal.show()
        })
        .catch(() => {
          this.alertError('Failed to fetch IAP data.')
        })
    }
  }
}
</script>

<style scoped>
.badge-box {
  width: 70px;
  height: 30px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.badge-dark-blue {
  background-color: #0047FF;
  color: white;
}
.badge-medium-blue {
  background-color: #5F73FF;
  color: white;
}
.badge-light-blue {
  background-color: #C9D4FF;
  color: #333 !important; /* or #333 for better readability */
}
.badge-orange {
  background-color: #FF9800;
}
.badge-iap-empty {
  background-color: #808080;
  position: relative;
  overflow: hidden;
  display: flex; /* Make sure it participates in flex */
  align-items: center;
  justify-content: center;
}
.badge-iap-empty::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 220%;
  height: 100%;
  border-top: 2px solid white;
  transform: rotate(30deg);
  transform-origin: center;
}
</style>
